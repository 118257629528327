<template>
    <div class="topContent">
        <div class="top brand">
            <div class="right-btn">
                <slot name="button"></slot>
            </div>

            <div class="flex1"></div>
            <el-input
                size="mini"
                :placeholder="$t('sou-suo')"
                class="search"
                v-model="search"
                clearable
                @change="submitSearch"
                @clear="submitSearch"
            >
                <div slot="append" @click="submitSearch" class="iconfont">&#xe603;</div>
            </el-input>
        </div>

        <el-divider></el-divider>
        <div class="typeSelect">
            <el-button
                v-for="(item, index) in collectType"
                :type="item.key === type ? 'warning' : 'info'"
                :key="index"
                plain
                size="small"
                @click="chooseType(item.key)"
                :disabled="loading"
                >{{ $t(item.name) }}</el-button
            >
        </div>
    </div>
</template>
<script>
import { productListType } from '../../utils/AppState';
export default {
    name: 'topSelect',
    props: {
        searchKey: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        hasOwn: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: ''
        };
    },
    watch: {
        searchKey() {
            this.$nextTick(() => {
                if (this.search !== this.searchKey) {
                    this.search = this.searchKey;
                }
            });
        },
        search() {
            this.$nextTick(() => {
                if (this.search !== this.searchKey) {
                    this.$emit('update:searchKey', this.search);
                }
            });
        }
    },
    methods: {
        submitSearch() {
            this.$nextTick(() => {
                this.$emit('submitSearch');
            });
        },
        chooseType(key) {
            this.$emit('update:type', key);
        }
    },
    computed: {
        collectType() {
            return [
                ...[...productListType]
                    .map(item => {
                        return {
                            key: item[0],
                            name: item[1]
                        };
                    })
                    .filter(item => {
                        if (!this.hasOwn && this.isVendor && item.key === 'OWN_PRODUCT') {
                            return false;
                        } else {
                            return true;
                        }
                    }),
                {
                    key: 'ProductsTransaction',
                    name: 'chan-pin-jiao-yi-deng-ji'
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.top {
    display: flex;
    height: 68px;
    align-items: center;
    .el-button {
        min-width: 120px;
        padding: 13px 15px;
        display: block;
    }
}
.right-btn {
    display: flex;
    align-items: center;
}
.el-divider {
    margin: 0;
}
.topContent {
    background-color: #fff;
    padding: 0 20px;
    .typeSelect {
        height: 51px;
        display: flex;
        align-items: center;
        .el-button--warning.is-plain {
            background-color: transparent;
            border-color: transparent;
            &:hover,
            &:checked,
            &:focus {
                color: $--color-warning;
            }
        }

        .el-button--info.is-plain {
            background-color: transparent;
            border-color: transparent;
            &:hover,
            &:checked {
                // background-color: #f3f4f5;
                color: $--color-warning;
            }
            &:focus {
                color: $--color-info;
            }
        }
    }
}

.search {
    height: 32px;
    display: flex;
    width: auto;
    margin-left: 30px;
}
</style>
