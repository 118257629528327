<template>
    <div class="order" ref="contain">
        <OrderTop
            :hasOwn="hasOwn"
            :loading="loading"
            :type.sync="type"
            :searchKey.sync="searchKey"
            @submitSearch="submitSearch"
        >
            <template slot="button">
                <el-button
                    v-if="!(isVendor && type === 'OWN_PRODUCT')"
                    type="warning"
                    size="small"
                    @click="
                        $router.push(
                            type === 'ProductsTransaction'
                                ? '/mineProductsTransactionEdit'
                                : '/mineOrderEdit?type=' + type
                        )
                    "
                >
                    {{ $t('xin-zeng') }}
                </el-button>
                <el-button type="primary" v-if="!empty" size="small" :loading="downloading" @click="downloadData">
                    {{ $t('dao-chu') }}
                </el-button>
            </template>
        </OrderTop>

        <el-table
            :data="list"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            v-loading="loading"
            style="width: 100%"
            :height="height"
            class="orderTable"
        >
            <el-table-column prop="brand" :label="$t('chan-pin-pin-pai')" show-overflow-tooltip min-width="120px">
            </el-table-column>
            <el-table-column
                :prop="type === 'ProductsTransaction' ? 'chName' : 'name'"
                :label="$t('chan-pin-pin-ming')"
                show-overflow-tooltip
                min-width="120px"
            >
            </el-table-column>
            <el-table-column prop="model" :label="$t('chan-pin-xing-hao')" show-overflow-tooltip min-width="100px">
            </el-table-column>
            <el-table-column
                prop="productCategory"
                :label="$t('chan-pin-lei-bie')"
                show-overflow-tooltip
                min-width="120px"
                :formatter="formatterProductCategory"
            >
            </el-table-column>
            <el-table-column
                :prop="type === 'ProductsTransaction' ? 'application' : 'applicationField'"
                :label="$t('ying-yong-ling-yu')"
                show-overflow-tooltip
                min-width="120px"





                
                :formatter="formatterApplocation"
            >
            </el-table-column>
            <el-table-column
                prop="introduction"
                :label="$t('chan-pin-miao-shu')"
                show-overflow-tooltip
                min-width="100px"
            >
            </el-table-column>
            <el-table-column :label="$t('cao-zuo')" align="center" fixed="right" min-width="80">
                <template slot-scope="{ row }">
                    <el-button
                        @click="
                            $router.push(
                                (type === 'ProductsTransaction'
                                    ? '/mineProductsTransactionEdit?id='
                                    : '/mineOrderEdit?id=') + row.id
                            )
                        "
                        type="warning"
                        plain
                        size="mini"
                    >
                        {{ $t('bian-ji') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-footer>
            <el-pagination
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </el-footer>
    </div>
</template>
<script>
import pageableList from '../../mixins/pageableList';
import OrderTop from '../../components/order/Top.vue';
export default {
    name: 'Order',
    mixins: [pageableList],
    data() {
        return {
            url: '/productList/all',
            formType: { body: 'json' },
            type: 'OWN_PRODUCT',
            height: 200,
            downloading: false,
            hasOwn: true
        };
    },
    computed: {
        listQuery() {
            if (this.type !== 'ProductsTransaction') {
                return {
                    query: {
                        userId: this.$store.state.userInfo.id,
                        type: this.type,
                        del: false
                    }
                };
            } else {
                return {
                    query: {
                        userId: this.$store.state.userInfo.id,
                        // type: this.type,
                        del: false
                    }
                };
            }
        }
    },
    watch: {
        type() {
            if (this.type === 'ProductsTransaction') {
                this.url = '/productsTransaction/all';
            } else {
                this.url = '/productList/all';
            }
            this.$nextTick(() => {
                this.getData();
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.height = this.$refs.contain.offsetHeight - 210;
        });
        this.$http
            .post(
                '/productList/all',
                {
                    query: {
                        userId: this.$store.state.userInfo.id,
                        type: 'OWN_PRODUCT',
                        del: false
                    },
                    size: 1,
                    page: 0
                },
                { body: 'json' }
            )
            .then(res => {
                if (res.empty) {
                    this.hasOwn = false;
                } else {
                    this.hasOwn = true;
                }

                if (!this.hasOwn && this.isVendor) {
                    this.type = 'REQUIRED_PRODUCT';
                }
                this.getData();
            });
    },
    components: {
        OrderTop
    },
    methods: {
        downloadData() {
            this.downloading = true;

            this.$axios
                .get(this.type === 'ProductsTransaction' ? '/productsTransaction/excel' : '/productList/excel', {
                    responseType: 'blob',
                    params: { ...this.listQuery, page: 0, size: 999 }
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        formatterApplocation(row, column, cellValue, index) {
            if (cellValue) {
                return (
                    [...cellValue]
                        .map(item => {
                            return this.getName(item);
                        })
                        .join(',') + (row.customApplicationField || '')
                );
            } else {
                return '' + (row.customApplicationField || '');
            }
        },
        formatterProductCategory(row, column, cellValue, index) {
            if (row.type !== 'OWN_PRODUCT') {
                return this.getName(cellValue);
            } else {
                return row.customCategory;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.order {
    background-color: transparent !important;
    overflow: hidden;
}
.el-table {
    margin: 20px 0 0;
    // min-height: calc( max( 100% , 1000px) - 190px);

    @include max-height(210px, $total: 100%, $max: 500px);
    overflow: hidden;
}
.el-footer {
    background-color: #fff;
    height: 68px !important;
}
</style>
<style lang="scss">
.orderTable.el-table th > .cell {
    font-size: 12px;
}
</style>