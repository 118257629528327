var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"contain",staticClass:"order"},[_c('OrderTop',{attrs:{"hasOwn":_vm.hasOwn,"loading":_vm.loading,"type":_vm.type,"searchKey":_vm.searchKey},on:{"update:type":function($event){_vm.type=$event},"update:searchKey":function($event){_vm.searchKey=$event},"update:search-key":function($event){_vm.searchKey=$event},"submitSearch":_vm.submitSearch}},[_c('template',{slot:"button"},[(!(_vm.isVendor && _vm.type === 'OWN_PRODUCT'))?_c('el-button',{attrs:{"type":"warning","size":"small"},on:{"click":function($event){return _vm.$router.push(
                        _vm.type === 'ProductsTransaction'
                            ? '/mineProductsTransactionEdit'
                            : '/mineOrderEdit?type=' + _vm.type
                    )}}},[_vm._v(" "+_vm._s(_vm.$t('xin-zeng'))+" ")]):_vm._e(),(!_vm.empty)?_c('el-button',{attrs:{"type":"primary","size":"small","loading":_vm.downloading},on:{"click":_vm.downloadData}},[_vm._v(" "+_vm._s(_vm.$t('dao-chu'))+" ")]):_vm._e()],1)],2),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"table",staticClass:"orderTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"row-key":"id","header-row-class-name":"table-header-row","header-cell-class-name":"table-header-cell","row-class-name":"table-row","cell-class-name":"table-cell","height":_vm.height}},[_c('el-table-column',{attrs:{"prop":"brand","label":_vm.$t('chan-pin-pin-pai'),"show-overflow-tooltip":"","min-width":"120px"}}),_c('el-table-column',{attrs:{"prop":_vm.type === 'ProductsTransaction' ? 'chName' : 'name',"label":_vm.$t('chan-pin-pin-ming'),"show-overflow-tooltip":"","min-width":"120px"}}),_c('el-table-column',{attrs:{"prop":"model","label":_vm.$t('chan-pin-xing-hao'),"show-overflow-tooltip":"","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"productCategory","label":_vm.$t('chan-pin-lei-bie'),"show-overflow-tooltip":"","min-width":"120px","formatter":_vm.formatterProductCategory}}),_c('el-table-column',{attrs:{"prop":_vm.type === 'ProductsTransaction' ? 'application' : 'applicationField',"label":_vm.$t('ying-yong-ling-yu'),"show-overflow-tooltip":"","min-width":"120px","formatter":_vm.formatterApplocation}}),_c('el-table-column',{attrs:{"prop":"introduction","label":_vm.$t('chan-pin-miao-shu'),"show-overflow-tooltip":"","min-width":"100px"}}),_c('el-table-column',{attrs:{"label":_vm.$t('cao-zuo'),"align":"center","fixed":"right","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('el-button',{attrs:{"type":"warning","plain":"","size":"mini"},on:{"click":function($event){_vm.$router.push(
                            (_vm.type === 'ProductsTransaction'
                                ? '/mineProductsTransactionEdit?id='
                                : '/mineOrderEdit?id=') + row.id
                        )}}},[_vm._v(" "+_vm._s(_vm.$t('bian-ji'))+" ")])]}}])})],1),_c('el-footer',[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 20, 30, 40, 50],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.totalElements},on:{"size-change":_vm.onSizeChange,"current-change":_vm.onCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }